<template>
  <div
    id="landingpage"
    :class="isProgramTypeTraining? 'training-program email-application': 'email-application'"
  >
    <div
      v-if="isChampionPreviewView"
      class="alert alert-danger alert-header alert-dismissible fade show"
      role="alert"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="redirectBackToEditProgram"
      >
        <span aria-hidden="true">×</span>
      </button>
      Close Preview
    </div>
    <!-- Banner -->
    <header class="banner-section">
      <div
        class="inner-header"
        :style="bannerImageStyle"
      >
        <div class="overlay" />
        <div class="img-header" />
        <div
          class="b-container"
        >
          <div
            id="header"
            class="title-banner"
            role="banner"
          >
            <h1
              class="b-header"
              role="banner"
              aria-label="header"
            >
              {{ program.landingPageTitle }}
            </h1>
            <div class="b-tagline">
              <!-- eslint-disable vue/no-v-html -->
              <span
                role="heading"
                v-html="program.tagline"
              />
              <!-- eslint-disable -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isProgramTypeTraining" class="b-action-group">
        <div class="bag-overlay" :style="bagOverlayStyle"/>
        <div
          v-if="!isProgramSamlEnabled"
          class="bag-content"
        >
          <div class="bag-box">
            <div class="bag-overlay-mobile" />
            <div
              class="bag-title"
              role="heading"
              :class="{ 'm-0': isProgramClosed}"
            >
              {{ makeApplyText() }}
            </div>
            <div
              v-if="!isProgramClosed"
              class="bag-action"
            >
              <router-link
                :to="{ name: 'participant-register', params: { clientSlug:clientPath, programPath: $route.params.programPath } }"
                class="btn btn-primary btn-lg"
              >
                Register Now
              </router-link>
            </div>
          </div>
          <div class="bag-vertical-line" />
          <div class="bag-box">
            <div class="bag-overlay-mobile" />
            <div class="bag-title" role="heading">
              Returning Participant
            </div>
            <div class="bag-action">
              <router-link
                v-if="isParticipantPortal && isLoggedIn"
                :to="{ name: 'participant-home', params: { clientSlug: clientPath, programPath: $route.params.programPath } }"
                class="btn btn-primary btn-lg"
              >
                Program Portal
              </router-link>
              <router-link
                v-else-if="!isLoggedIn"
                :to="{ name: 'participant-login', params: { clientSlug:clientPath, programPath: $route.params.programPath } }"
                class="btn btn-primary btn-lg"
              >
                Log In
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-else
          class="bag-content"
        >
          <div class="bag-box">
            <div
              class="bag-title"
              role="heading"
   
            >
              {{ makeApplyText() }}
            </div>
            <div
              class="bag-action d-flex justify-content-center"
              :style="{ width: '240px' }"
            >
              <router-link
                :to="{ name: 'participant-login', params: { clientSlug: clientPath, programPath: $route.params.programPath } }"
                class="btn btn-primary btn-lg"
              >
                {{
                  isProgramClosed ? "Log In" : "Apply or Log In"
                }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="b-action-group">  
        <div class="bag-content">
          <div class="bag-box">
            <div class="bag-overlay-mobile" />
              <div class="bag-title" role="heading">
                <span>{{ `Returning ${roleAliasDisplay}`}}</span>
              </div>
              <div class="bag-action">
                <router-link
                  v-if="isParticipantPortal && isLoggedIn"
                  :to="{ name: 'participant-home', params: { clientSlug: clientPath, programPath: $route.params.programPath } }"
                  class="btn btn-primary btn-lg"
                >
                  Trainee Portal
                </router-link>
                <router-link
                  v-else-if="!isLoggedIn"
                  :to="{ name: 'participant-login', params: { clientSlug:clientPath, programPath: $route.params.programPath } }"
                  class="btn btn-primary btn-lg"
                >
                  Log In
                </router-link>
              </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Program detail -->
    <section class="program-detail-section">
      <b-container fluid="sm">
        <div class="row">
          <div class="col">
            <div :class="isProgramTypeTraining? 'pd-rightbox col-6': 'pd-rightbox'">
              <b-button
                class="w-100"
                variant="primary"
                @click="onShowEmailCompose"
              >
                Contact Program Manager
              </b-button>
              <landing-page-key-dates v-if="program.key_dates && program.key_dates.length"/>
              <!-- Social -->
              <div  v-if="hasSocialLinks">
                <div
                  class="social-title primary-color"
                  role="heading"
                >
                  Social
                </div>
                <b-list-group
                  flush
                  class="flex-row justify-content-center mt-1 social-list"
                >
                  <template v-for="(link, index) in program.landing_page_links">
                    <b-list-group-item
                      v-if="link.type_id !== links.RECRUITMENT_VIDEO"
                      :key="index"
                      :href="getLinkHref(link)"
                      target="_blank"
                      role="button"
                      aria-label="opens in new tab"
                      class="social-item border-0 text-center"
                    >
                      <feather-icon
                        :icon="linkIconsDisplay[link.type_id]"
                        size="30"
                      />
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </div>
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <div
              :class="isProgramTypeTraining? 'pd-content program-desc col-6': 'pd-content program-desc'"
              v-html="program.description"
            />
            <!-- eslint-disable -->
          </div>
        </div>
      </b-container>
    </section>

    <!-- Video Embeded -->
    <section
      v-if="program.recruitment_video_url"
      class="video-embeded-section"
    >
      <div class="container">
        <h2 class="ve-header title primary-color">
          Informational Video
        </h2>
        <div class="ve-body">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              title="Program instructional video"
              aria-label="Program instructional video"
              class="embed-responsive-item"
              :src="convertProtocol(program.recruitment_video_url)"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Responsibility -->
    <section
      v-if="program.mentees_body != '' || program.mentor_body != ''"
      class="responsibility-section"
    >
      <div class="container">
        <div class="row">
          <div
            v-if="program.mentees_body"
            class="col-lg-6 mentor-res d-flex"
            :class="{
              'res-center':
                program.mentees_body !== '' && program.mentor_body === '',
            }"
          >
            <div class="rs-box" style="flex: 1">
              <div class="rs-header">
                <div class="rs-icon rs-icon-mentee secondary-fill-color">
                  <!-- user icon -->
                  <img
                    src="@/assets/images/svg/user.svg"
                    alt="user icon"
                    aria-hidden="true"
                    role="presentation"
                  >
                  <!-- end user icon -->
                </div>
                <div class="rs-title primary-color" role="heading">
                  {{ roleAlias(userRoles.MENTEE, program) }} Responsibilities
                </div>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="menteeShowMoreToggle"
                class="rs-content ment-resp"
                v-html="program.mentees_body"
              />
              <div
                v-else
                class="rs-content ment-resp"
                v-html="
                  truncateResponsibilities(program.mentees_body)
                "
              />
              <!-- eslint-disable -->
              <div
                v-if="activeShowMoreToggle('mentee')"
                class="rs-action"
              >
                <button
                  v-if="!menteeShowMoreToggle"
                  class="btn btn-link secondary-color"
                  @click="showMore('mentee', true)"
                >
                  Show More <i
                    class="fa fa-caret-down"
                    aria-hidden="true"
                  />
                </button>
                <button
                  v-else
                  class="btn btn-link secondary-color"
                  @click="showMore('mentee', false)"
                >
                  Show Less <i
                    class="fa fa-caret-up"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="program.mentor_body"
            class="col-lg-6 mentor-res d-flex"
            :class="{
              'res-center':
                program.mentor_body !== '' && program.mentees_body === '',
            }"
          >
            <div class="rs-box" style="flex: 1">
              <div class="rs-header">
                <div class="rs-icon rs-icon-mentor secondary-fill-color">
                  <!-- handshake icon -->
                  <img
                    src="@/assets/images/svg/handshake.svg"
                    alt="handshake icon"
                    aria-hidden="true"
                    role="presentation"
                  >
                  <!-- end handshake icon -->
                </div>
                <div class="rs-title primary-color" role="heading">
                  {{ roleAlias(userRoles.MENTOR, program) }} Responsibilities
                </div>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="mentorShowMoreToggle"
                class="rs-content ment-resp"
                v-html="program.mentor_body"
              />
              <div
                v-else
                class="rs-content ment-resp"
                v-html="
                  truncateResponsibilities(program.mentor_body)
                "
              />
              <!-- eslint-disable -->
              <div
                v-if="activeShowMoreToggle('mentor')"
                class="rs-action"
              >
                <button
                  v-if="!mentorShowMoreToggle"
                  class="btn btn-link secondary-color"
                  @click="showMore('mentor', true)"
                >
                  Show More <i
                    class="fa fa-caret-down"
                    aria-hidden="true"
                  />
                </button>
                <button
                  v-else
                  class="btn btn-link secondary-color"
                  @click="showMore('mentor', false)"
                >
                  Show Less <i
                    class="fa fa-caret-up"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Sponsor -->
    <section
      v-show="
        program.program_sponsor_logos && program.program_sponsor_logos.length
      "
      class="sponsor-section"
    >
      <div class="wrap-section">
        <h2 class="sponsor-title title primary-color">
          Proudly Sponsored By
        </h2>
        <div class="sponsor-content">
          <div
            v-for="(photo, index) in program.program_sponsor_logos"
            :key="index"
            class="sponsor-logo"
          >
            <div v-if="photo.link != ''">
              <a
                :href="photo.link"
                target="_blank"
                role="button"
                aria-label="opens in new tab"
              ><img
                class="sponsor-img"
                :src="photo.url"
                alt="sponsor company logo"
              ></a>
            </div>
            <div v-else-if="photo.link">
              <a
                target="_blank"
                role="button"
                aria-label="opens in new tab"
              ><img
                class="sponsor-img"
                :src="photo.photo"
                alt="sponsor company logo"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Email compose modal -->
    <email-compose
      v-model="shallShowEmailComposeModal"
      :isProgramCommunications="true"
      :isPublic="true"
    />
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BButton,
  BContainer
} from "bootstrap-vue";
import { userRoles } from '@models/userRoles';
import { programStatus, linkIconsDisplay, links, programTypes } from "@/models/enums/ePrograms";
import LandingPageKeyDates from '@/participants/LandingPage/KeyDates.vue';
import EmailCompose from "@/participants/communications/EmailCompose.vue";
import { ref } from '@vue/composition-api';
import { mapGetters, mapActions } from "vuex";
import { makeErrorToast } from "@/libs/utils";
import { roleAlias } from "@aom-core/utils/utils";
import { participantRolesDisplay } from '@models/userRoles';

export default {
  name: "LandingPage",
  components: {
    BListGroup,
    BListGroupItem,
    LandingPageKeyDates,
    BButton,
    EmailCompose,
    BContainer
  },
  props: {
    programPath: {
      type: String,
      default: "",
    }, 
    subdomain:{
      type: String,
      default: window.location.host.split('.').slice(0, -2).join('.')
    }
  },
  data() {
    return {
      menteeShowMoreToggle: false,
      mentorShowMoreToggle: false,
      linkIconsDisplay,
      links
    };
  },
  computed: {
    ...mapGetters("app", ["currentProgram"]),

    isLoggedIn() {
      return this.$store.getters['profile/isLoggedIn'];
    },

    isParticipantPortal() {
      return this.$store.getters['app/isParticipantPortal'];
    },
    program() {
      return this.$store.getters['participants/program'];
    },
    hasSocialLinks() {
      return this.program.landing_page_links?.filter(link => link.type_id !== 7).length > 0;
    },
    bannerImageStyle() {
      if (!this.program.banner_image) { return {}; }
      var imageUrl = "/img/banner.jpg";

      if (this.program.banner_image) {
        imageUrl = this.program.banner_image;
      }

      return { "background-image": 'url("' + imageUrl + '")' };
    },
    bagOverlayStyle() {
      return this.program.banner_image ? { "background-color": '#002a53' } : {};
    },
    isChampionPreviewView() {
      return this.$route && this.$route.name === "champion-preview";
    },
    isProgramClosed() {
      return this.program.status_id !== programStatus.ACTIVE ||
        !this.program.registration_status ||
        this.program.registration_status.is_active_participant_limit_reached ||
        this.program.registration_status.applications?.filter(i => i.is_open).length === 0;
    },
    clientPath() {
      return this.currentProgram?.client?.path || this.subdomain;
    },
    isProgramSamlEnabled() {
      return this.currentProgram?.program_saml?.is_enabled;
    },
    isProgramTypeTraining() {
      return this.currentProgram?.type_id === programTypes.TRAINING;
    },
    roleAliasDisplay() {
      const aliases = participantRolesDisplay.reduce((aliases, role) => {
        if (role.value === userRoles.TRAINEE || role.value === userRoles.MENTOR || role.value === userRoles.MENTEE) {
          const alias = roleAlias(role.value, this.program);
          if (alias !== role.text) {
            aliases.push(alias);
          }
        }
        return aliases;
      }, []);

      if (!aliases || !aliases.length) {
        return "Participant";
      }

      return aliases.join("/");
    }
  },
  created() {
    this.menteeShowMoreToggle = !this.activeShowMoreToggle(userRoles.MENTEE);
    this.mentorShowMoreToggle = !this.activeShowMoreToggle(userRoles.MENTOR);
    if (this.program && this.program.id) {
      this.$root.$emit("show-loading", true);
    }
    if(!this.subdomain || !this.programPath) {
      this.$router.push({ name: 'error-404' });
      //return this.$toast(makeErrorToast("Missing Program or Organisation names"));
    }
    
    this.loadProgramBySubdomainPath();
  },

  methods: {
    ...mapActions("app", ["FETCH_CURRENT_PROGRAM"]),
    showMore: function (type, status) {
      if (type == userRoles.MENTEE) {
        this.menteeShowMoreToggle = status;
      }

      if (type == userRoles.MENTOR) {
        this.mentorShowMoreToggle = status;
      }
    },

    contactProgramManager() {
      this.$router.push({
        name: "contact-program-manager",
      });
    },

    convertProtocol(link) {
      let protocol = window.location.protocol;
      let embeddedUrl = this.getYoutubeEmbeddedUrl(link);

      protocol = protocol.replace(/:/g, "");

      if (protocol == "https") {
        return embeddedUrl.replace(/https?/i, "https");
      }

      return embeddedUrl;
    },

    truncateResponsibilities(content) {
      if (content && content.length > 250) {
        // return _.truncate(content, {
        // 	'length': 250,
        // 	'separator': ' '
        // });
      }

      return "";
    },
    activeShowMoreToggle(role) {
      if (role == userRoles.MENTEE) {
        return (
          this.program &&
          this.program.mentees_body &&
          this.program.mentees_body.length > 250
        );
      }

      if (role == userRoles.MENTOR) {
        return (
          this.program &&
          this.program.mentor_body &&
          this.program.mentor_body.length > 250
        );
      }
    },

    makeApplyText() {
      if (this.isProgramClosed) {
        return "Applications closed";
      } else if (
        this.program.mentor_available &&
        !this.program.mentee_available
      ) {
        return `${roleAlias(userRoles.MENTOR, this.program) } Applications now open`;
      } else if (
        !this.program.mentor_available &&
        this.program.mentee_available
      ) {
        return `${roleAlias(userRoles.MENTEE, this.program) } Applications now open`;
      } else {
        return `${roleAlias(userRoles.MENTOR, this.program) } & ${roleAlias(userRoles.MENTEE, this.program) } applications now open`;
      }
    },
    redirectBackToEditProgram() {
      if (this.$route && this.$route.query.redirectBack) {
        window.location.href = `${this.$route.query.redirectBack}`;
      }
    },
    onShowEmailCompose() {
      this.shallShowEmailComposeModal = true;
    },
    async loadProgramBySubdomainPath() {
      try {
        const programPath = this.programPath;
        const subdomain = this.subdomain;
        await this.FETCH_CURRENT_PROGRAM({ subdomain, programPath });
      } catch(e) {
        console.log(e);
        const {status} = e.response;
        if(status === 404) {
          // Program or Organisation not found
          this.$router.push({ name: 'error-404' });
        }
        return this.$toast(makeErrorToast(e));
      }
    },

    getLinkHref(link) {
      if (link.type_id === this.links.EMAIL) {
        return `mailto:${link.url}`;
      }
      return link.url;
    },

    getYoutubeEmbeddedUrl(videoUrl) {
      if (videoUrl.includes('embed')) {
        return videoUrl;
      }

      const url = new URL(videoUrl);

      const videoId = videoUrl.includes('youtu.be')
        ? url.pathname.slice(1)
        : url.searchParams.get('v');

      return `https://www.youtube.com/embed/${videoId}`;
    }
  },

  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);

    return {
      shallShowEmailComposeModal,
      roleAlias,
      userRoles
    };
  }
};
</script>

<style lang="scss" scoped>
nav.navbar-section {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 1, 0.09);
  padding: 0.8rem 1rem;

  .container {
    display: flex;
    justify-content: space-between;
  }

  .program-logo {
    height: auto;
    width: auto;
    max-height: 55px;
    max-width: 230px;
  }
  .program-name {
    padding: 0px 20px;
    font-size: 18px;
    color: rgb(41, 41, 41);
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    display: flex;
    flex-grow: 2;
    justify-content: center;
  }
}

header.banner-section {
  position: relative;
  background-color: #fff;
  .inner-header {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .img-header {
    min-height: 700px;
    img {
      width: 100%;
      margin: 0 auto;
      opacity: 0;
    }
  }
  .overlay {
    position: absolute;
    background-color: rgb(0, 42, 83);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
  }

  .b-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
    .title-banner {
      margin-bottom: 180px;
    }
    .b-header {
      font-size: 48px;
      color: rgb(255, 255, 255);
      font-weight: 500;
      line-height: 1.25;
      text-align: center;
      margin-bottom: 20px;
      text-transform: capitalize;
      padding: 0px 15px;
    }

    .b-tagline {
      font-size: 24px;
      color: #fff;
    }
  }
  .b-action-group {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 0;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;

    .bag-overlay {
      position: absolute;
      background-color: var(--aom-color-primary);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.5;
    }

    .bag-content {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      z-index: 2;

      .bag-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 0px 10px;

        .bag-title {
          font-size: 22px;
          color: #ffffff;
          font-weight: 500;
          text-align: center;
          margin-bottom: 40px;
        }

        .bag-action {
          margin-bottom: 15px;
          // height: 44px;

          .btn {
            padding: 9px 50px;
          }
        }

        .bag-overlay-mobile {
          display: none;
        }
      }

      .bag-vertical-line {
        background-color: rgb(255, 255, 255);
        height: 131px;
        width: 1px;
        margin: 0 100px;
      }
    }
  }

  .banner-item {
    text-align: center;

    .banner-item-title {
      font-size: 30px;
      color: #fff;
      margin-bottom: 20px;
    }

    .banner-item-action {
      button {
        border-radius: 3px;
        border: 0;
        background-color: rgb(73, 73, 203);
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        color: #fff;
        box-shadow: none;
      }
    }
  }
}

.program-detail-section {
  background-color: #fff;
  padding: 60px 0;

  .pd-content {
    font-size: 16px;
    color: rgb(76, 84, 109);
    line-height: 1.5;
    text-align: left;
  }

  .pd-rightbox {
    float: right !important;
    padding: 15px 0 30px 30px;
    max-width: 470px;
    width: 100%;

    .lqv-contact-program-manager {
      font-size: 18px;
      color: white;
      font-weight: 500;
      border-radius: 4px;
      background-color: var(--aom-color-primary);
      padding: 8px 12px;
      margin-bottom: 20px;
      text-align: center;
      cursor: pointer;

      img {
        width: 27px;
        height: 18px;
        margin-right: 5px;
      }
    }

    .contact-program-manager-box {
      .btn-contact-program-manager {
        font-size: 18px;
        color: rgb(255, 255, 255);
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        border-radius: 4px;
        background-color: var(--aom-color-primary);
        height: 44px;
        box-shadow: none;
      }
    }
  }

  .social-title {
    font-size: 24px;
    color: rgb(0, 42, 83);
    font-weight: 500;
    text-align: center;
    padding-top: 20px;
  }

  .social-list {
    background-color: #f5f7fd;
    border-bottom: 4px solid var(--aom-color-secondary);
   
    .social-item {
      width: calc(100% / 6);
      background-color: #f5f7fd;

    }
  }
}

.video-embeded-section {
  background-color: rgb(247, 247, 247);
  padding: 60px 0;
  text-align: center;

  .ve-header {
    text-align: center;
  }

  .ve-body {
    background-color: rgb(0, 0, 0);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 1, 0.09);
    max-width: 770px;
    height: auto;
    width: 100%;
    margin: 0 auto;
  }
}

.responsibility-section {
  padding: 60px 0;
  background-color: rgb(247, 247, 247);

  .rs-box {
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 1px 6px 0 rgba(0, 0, 1, 0.15);
    padding: 30px 50px;

    .rs-header {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-bottom: 30px;
      height: 40px;

      .rs-icon {
        margin-right: 10px;
      }

      .rs-icon-mentee {
        width: 30px;
        height: 30px;
      }

      .rs-icon-mentor {
        width: 43px;
        height: 40px;
      }

      .rs-title {
        font-size: 22px;
        color: rgb(0, 42, 83);
        font-weight: 500;
        line-height: 1.2;
      }
    }

    .rs-content {
      font-size: 15px;
      margin-bottom: 20px;
      color: #4c546d;
      text-align: left;
      padding: 0px 20px;
      overflow: hidden;
      ul {
        list-style: none;
        padding: 0;
        li {
          position: relative;
          &:before {
            content: "•";
            position: absolute;
            left: -20px;
            top: 0;
            color: inherit;
            font-weight: 500;
            margin-left: 0;
          }
        }
      }
      ol {
        padding: 0;
        counter-reset: item;
        list-style-type: none;
        li {
          position: relative;
          &:before {
            content: counter(item) ". ";
            counter-increment: item;
            position: absolute;
            left: -20px;
            top: 0;
            color: inherit;
            margin-left: 0;
          }
        }
      }
    }

    .rs-action {
      button {
        font-size: 14px;
        color: rgb(154, 206, 109);
        text-decoration: none;
      }
    }
  }
}

.apply-box {
  max-width: 470px;
  width: 100%;
  height: 214px;
  position: relative;
  margin: 0 auto;

  .ab-bg {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.702;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 1, 0.07);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .ab-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .ab-title {
      font-size: 22px;
      color: rgb(0, 42, 83);
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 30px;
    }

    .ab-action {
      a {
        font-size: 18px;
        color: rgb(255, 255, 255);
        font-weight: 500;
        line-height: 1.2;
        text-align: center;

        border-radius: 4px;
        background-color: #9bcc65;
        width: 196px;
        height: 44px;
        padding: 10px 12px;
        box-shadow: none !important;
      }
    }
  }
}

.sponsor-section {
  text-align: center;
  padding: 60px 0;
  background-color: rgb(255, 255, 255);

  .sponsor-content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .sponsor-logo {
      max-width: 190px;
      max-height: 130px;
      padding: 0 15px;
      display: block !important;

      .sponsor-img {
        width: 100%;
        max-width: 190px;
        max-height: 130px;
      }
    }
  }
}

footer.footer-section {
  background-color: rgb(49, 52, 61);
  color: #fff;
  padding: 0px !important;
  .wrap-container {
    padding: 50px;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
  }
  .footer-landingpage {
    padding: 50px 15px;
  }
}

.registration-closed {
  color: #9ace6d;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  font-size: 24px;
  padding: 18px 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.registration-closed-no-bg {
  color: #9ace6d;
  font-size: 24px;
  background-color: transparent;
}

.menu-mobile {
  flex-grow: 0 !important;
}

@media only screen and (max-width: 991px) {
  footer.footer-section {
    .footer-landingpage,
    .wrap-container {
      max-width: 100%;
      padding: 30px 40px;
      margin: 0 auto;
    }
  }
  nav.navbar-section {
    padding: 0px !important;
    .container {
      max-width: 100%;
      width: 100%;
    }

    .container {
      padding: 15px 40px;
      position: relative;
      .imglogo {
        display: inline-block;
        max-width: 220px;
        margin: 0px;
        position: absolute;
      }
      .program-logo {
        max-width: 160px;
      }
      .program-name {
        width: 100%;
        padding: 20px;
        display: inline-block;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
      }
      .menu-humburger {
        display: inline-block;
        margin-right: 40px;
        width: 54px;
        position: absolute;
        right: 0;
      }
    }
  }
  .menu-mobile {
    position: absolute;
    background-color: #002a53;
    top: 100%;
    z-index: 9999;
    left: 0;
    right: 0;
  }

  header.banner-section {
    .b-container {
      .b-header {
        font-size: 40px;
      }
    }
    .img-header {
      min-height: 550px;
    }
    .b-action-group {
      .bag-content {
        .bag-vertical-line {
          margin: 0 30px;
        }
      }
    }
  }
  .program-detail-section {
    padding-top: 15px;
    .pd-title {
      text-align: center;
    }
    .pd-rightbox {
      float: none !important;
      padding: 0 0 30px 0;
      margin: 0 auto;
    }
  }
  .responsibility-section {
    .mentor-res {
      margin-bottom: 20px;
    }
    .rs-box {
      padding: 30px;
      .show-more {
        height: auto;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .registration-closed {
    font-size: 18px;
  }
  nav.navbar-section {
    .container {
      padding: 15px 20px;
      .imglogo {
        position: initial;
      }
      .program-name {
        display: none;
      }
      .menu-humburger {
        position: initial;
        margin: 0px;
      }
    }
  }
  .sponsor-section {
    .sponsor-content {
      display: block;
      .sponsor-logo {
        margin: 0 auto;
        padding-bottom: 15px;
      }
    }
  }

  header.banner-section {
    .img-header {
      min-height: 400px;
    }
    .b-container {
      .title-banner {
        margin-bottom: 0px;
      }
      .b-header {
        font-size: 33px;
      }
      .b-tagline {
        font-size: 20px;
      }
    }

    .b-action-group {
      position: relative;
      background-color: #fff;
      .bag-content {
        display: block;
        padding-left: 1rem;
        padding-right: 1rem;

        .bag-vertical-line {
          display: none;
        }

        .bag-box {
          position: relative;
          border-radius: 4px;
          padding: 15px 10px;
          width: 100%;
          margin: 15px auto 0;

          .bag-title {
            z-index: 2;
            font-size: 18px;
            margin-bottom: 15px;
          }
          .bag-action {
            z-index: 2;
            margin-bottom: 0;
          }

          .bag-overlay-mobile {
            display: block;
            position: absolute;
            background-color: var(--aom-color-primary);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0.75;
          }
        }


      }

      .bag-overlay {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  nav.navbar-section {
    .container {
      .program-name {
        display: none;
      }
      .menu-hamburger {
        margin-right: 15px;
      }
    }
  }

  header.banner-section {
    .img-header {
      min-height: 350px;
    }
    .b-container {
      .b-header {
        font-size: 30px;
      }
    }
  }

  footer.footer-section {
    text-align: center;
    .wrap-container,
    .footer-landingpage {
      padding: 30px;
    }
    .row-column {
      flex-direction: column;
    }
  }
}
@media only screen and (max-width: 480px) {
  header.banner-section {
    .b-container {
      .b-header {
        font-size: 24px;
      }
      .b-tagline {
        font-size: 18px;
      }
    }
  }

  .responsibility-section {
    .rs-box {
      padding: 30px 15px;
      .show-more {
        padding: 0px;
      }
      .rs-header {
        .rs-icon {
          margin-right: 5px;
          img {
            width: 100%;
          }
        }
        .rs-title {
          font-size: 18px;
        }
      }
    }
  }
  .program-detail-section {
    .pd-rightbox {
      .key-program-date-box {
        .k-title {
          font-size: 20px;
        }
      }
    }
  }
}

.res-center {
  margin: 0 auto;
}

.error {
  font-size: 14px;
  color: rgb(228, 84, 108);
}

.download-link {
  &:hover {
    cursor: pointer !important;
  }
}

.disabled-button {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.alert-danger {
  border-radius: 0;
  border: none;
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background-color: #ff5555;

  .close {
    position: static;
    float: none;
    width: 32px;
    height: 32px;
    opacity: 1;
    text-indent: -999px;
    overflow: hidden;
    margin-right: 10px;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}

.responsibility-section .rs-box .rs-content ::v-deep ul li {
  &::before {
    position: relative;
    left: 0;
  }
}
.responsibility-section .rs-box .rs-content ::v-deep ol li {
  &::before {
    position: relative;
    left: 0;
  }
}
.program-desc {
  margin-top: 67px;
}
.training-program {
  .program-desc{
    margin-top: 0px;
  }
  .program-detail-section {
    .pd-rightbox {
      padding-top: 0px;
    }
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>